import { createTheme } from '@mui/material/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

// Material-UI Theme Creator
// https://bareynol.github.io/mui-theme-creator/
// https://demo.listivotheme.com/listing/google-pixel-4a-5g/

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const picpadTheme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  },
  palette: {
    type: 'light',
    // primary: {
    //   main: '#ffffff',
    // },
    // secondary: {
    //   main: '#ffc14a',
    // },
    // error: {
    //   main: '#da1c2e',
    // },
  },
})
