import Header from './Header/Header'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

function MainLayout () {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default MainLayout
