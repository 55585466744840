import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

console.log(`API: ${process.env.REACT_APP_API_BASE_URL}, project ID: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`)

// Global axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.withCredentials = true

export const axiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseAnalytics = getAnalytics(firebaseApp)

export function onAuthStateChange (callback) {
  return firebaseAuth.onAuthStateChanged(user => {
    if (user) {
      console.log('The user is logged in')
      console.log(user)
      callback(user)
    } else {
      console.log('The user is not logged in')
      callback(null)
    }
  })
}
