import { Link as RouterLink } from 'react-router-dom'
import { appRoutes } from '../../appRoutes'
import Button from '@mui/material/Button'
import React from 'react'

export default function GuestUserMenu () {

  return (
    <Button color='inherit' component={RouterLink} to={appRoutes.login.path}>
      Login
    </Button>
  )

}
