import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React from 'react'
import { useAuth } from '../../hooks/use-auth'
import avatarImage from '../../assets/img/avatar/1.jpg'

const settings = [
  { name: 'Profile', path: '/profile' },
  { name: 'Account', path: '/profile' },
  { name: 'Dashboard', path: '/profile' },
  { name: 'Logout', path: '/logout' },
]

export default function AuthenticatedUserMenu () {
  const auth = useAuth()
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (menu) => {
    setAnchorElUser(null)
    if (menu.name === 'Logout') {
      auth.logout()
    }
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Open settings'>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt='Remy Sharp' src={avatarImage} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.name} onClick={() => handleCloseUserMenu(setting)}>
            <Typography textAlign='center'>{setting.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )

}
