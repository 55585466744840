import React from 'react'

export default function RegistrationSuccess () {
  return (
    <div>
      Thank You For
      Signing Up!
      Check the confirmation email at saniaky@gmail.com
      Note: If you do not receive the email in few minutes:

      check spam folder
      verify if you typed your email correctly
      if you can't resolve the issue, please contact support@mailtrap.io.
    </div>
  )
}
