import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuthProvider } from './hooks/use-auth'
import RequireAuth from './components/PrivateRoute'
import MainLayout from './components/MainLayout'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import PageLoader from './components/PageLoader'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/material'
import RegistrationSuccess from './pages/SignupPage/SignupSuccess'
import { picpadTheme } from './theme'

const Home = React.lazy(() => import('./pages/HomePage/Home'))
const About = React.lazy(() => import('./pages/AboutPage/About'))
const Profile = React.lazy(() => import('./pages/ProfilePage/Profile'))
const Admin = React.lazy(() => import('./pages/AdminPage/Admin'))
const Login = React.lazy(() => import('./pages/LoginPage/Login'))
const Signup = React.lazy(() => import('./pages/SignupPage/Signup2'))

function App () {
  function pageLoader (Component, isPrivate) {
    return (
      <React.Suspense fallback={<PageLoader />}>
        {
          isPrivate
            ? <RequireAuth><Component /></RequireAuth>
            : <Component />
        }
      </React.Suspense>
    )
  }

  return (
    <AuthProvider>
      <div className='App'>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <ThemeProvider theme={picpadTheme}>
          <CssBaseline />
          <Routes>
            <Route path='/' element={<MainLayout />}>
              <Route index element={pageLoader(Home)} />
              <Route path='about' element={pageLoader(About)} />
            </Route>
            <Route path='/login' element={pageLoader(Login)} />
            <Route path='/register' element={pageLoader(Signup)} />
            <Route path='/register/success' element={pageLoader(RegistrationSuccess)} />
            <Route path='/profile' element={pageLoader(Profile, true)} />
            <Route path='/mallorca' element={pageLoader(Admin, true)} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </ThemeProvider>
      </div>
    </AuthProvider>
  )
}

export default App
