import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { onAuthStateChange } from '../config'

const authContext = createContext({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider (props) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth () {
  const [user, setUser] = useState(null)
  const firebaseAuth = getAuth()

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser)
    return () => {
      unsubscribe()
    }
  }, [])

  const isAuthenticated = () => {
    return !!user
  }

  // Wrap any method we want to use making sure to save the user to state.
  const login = (params) => {
    const persistence = params.rememberMe ? browserLocalPersistence : browserSessionPersistence
    return setPersistence(firebaseAuth, persistence)
      .then(() => {
        return signInWithEmailAndPassword(firebaseAuth, params.email, params.password)
      })
      .then(res => {
        setUser(res.user)
        return res.data
      })
  }

  const socialSignin = (params) => {
    return axios.post('/api/v1/auth/signin/social', params)
      .then(response => {
        setUser(response.data)
        return response.data
      })
  }

  const register = (params) => {
    return createUserWithEmailAndPassword(firebaseAuth, params.email, params.password)
      .then(res => {
        setUser(res.user)
        return res.user
      })
  }

  const logout = () => {
    return signOut(firebaseAuth)
      .then(() => {
        setUser(false)
      })
  }

  const sendPasswordResetToken = (params) => {
    return sendPasswordResetEmail(firebaseAuth, params.email)
      .then(() => {
        return true
      })
  }

  const confirmPasswordReset = (params) => {
    return axios.post('/api/v1/auth/confirm-password-reset', params)
      .then(() => {
        return true
      })
  }

  // Return the user object and auth methods
  return {
    user,
    isAuthenticated,
    login,
    socialSignin,
    register,
    logout,
    sendPasswordResetToken,
    confirmPasswordReset
  }
}
